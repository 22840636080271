import React, { useState } from "react";
import "./Carousel.scss"

interface CarousselParameter {
    slides: {
        icons: string,
        title: string,
        text: string,
        color: string
    }[]
}

interface CarouselItemParameter {
    icons: string,
    title: string,
    text: string,
    color: string
}

const CarouselItem: React.FC<CarouselItemParameter> = ({ icons, title, text}) => {
    return (
        <div className="carousel-item">
            <div className="img-container">
                <img loading="lazy" key={title} src={icons} alt={title}></img>
            </div>
            <div className="description">
                <h1>{title}</h1>
                <p>{text}</p>
            </div>
        </div>
    )
}

const Caroussel: React.FC<CarousselParameter> = ({ slides }) => {
    const [idx, setIdx] = useState(0)
    const [currentSlide, setCurrentSlide] = useState(slides[0])
    const [background_color, setBackgroundColor] = useState(slides[0].color)

    const updateIndex = (index: number) => {
        setIdx(index)
        setCurrentSlide(slides[index])
        setBackgroundColor(slides[index].color)
    }

    return (
        <div className="carousel" style={{backgroundColor: background_color}}>
            <div className="carousel-body">
                <CarouselItem icons={currentSlide.icons} title={currentSlide.title} text={currentSlide.text} color={currentSlide.color} />
            </div>
            <div className="carousel-buttons">
                {slides.map((s, index) => {
                    if (idx === index) {
                        return (
                            <button className="selected-button" onClick={() => { updateIndex(index) }} style={{backgroundColor: s.color}}></button>
                        )
                    } else {
                        return (
                            <button className="unselected-button" onClick={() => { updateIndex(index) }}></button>
                        )
                    }
                })}
            </div>
        </div>
    );
}
export default Caroussel;
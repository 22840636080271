import React from 'react';
import './Home.scss';
import Project from './project/Project';
import Roadmap from './roadmap/Roadmap';
import LandingScreen from './landingScreen/LandingScreen';
import Team from './team/Team';

function Home() {
  return (
    <div className="home">
      <LandingScreen></LandingScreen>
      <Project></Project>
      <Roadmap></Roadmap>
      <Team></Team>
    </div>
  );
}

export default Home;
import React from "react";
import "./Project.scss"
import Caroussel from "../../components/carousel/Carousel";

const Project: React.FC = () => {
    const actions = [
        {
            "icons": "./undraw_decide_re_ixfw 1.svg",
            "title": "Choisir vos actions",
            "text": "Vous pourrez choisir les actions que vous souhaitez suivre. Tupi vous en proposera chaque jour et ne vous en imposera jamais.",
            "color": "#FEA66F"
        },
        {
            "icons": "./undraw_selection_re_ycpo 1.svg",
            "title": "Valider vos actions",
            "text": "Il faut valider ses actions quotidiennement, ainsi Tupi peut les comptabiliser comme faites.",
            "color": "#82A86D"
        },
        {
            "icons": "./undraw_adventure_map_hnin 1.svg",
            "title": "Mon évolution",
            "text": "Au fur et à mesure vous pourrez constater votre évolution et Tupi vous accompagnera. Voir ses résultats et toujours gratifiant.",
            "color": "#83C5B0"
        }
    ]

    return (
        <div>
            <div className="collibri-story">
            <div id="wprojet" className="whitespace"></div>
                <div className="title">
                    <h1>Le Colibri</h1>
                    <h2>La théorie qui change tout</h2>
                </div>
                <div className="content">
                    <img src="./dropcoli 1.svg" alt="dropcoli"></img>
                    <p>Si chacun fait sa part on y arrivera.<br/> Le colibri fait sa part sans cynisme pour éteindre un feu de forêt en apportant de l'eau dans son bec. Ainsi tous les animaux finissent par suivre l'exemple. Il nous enseigne qu'il faut croire en l'effort collectif.</p>
                </div>
                <div className="catch-phrase">
                    <p>Vos actes <span>comptent.</span></p>
                </div>
                <div className="description">
                    <p>Tupi est une application conçue pour soutenir les individus dans leur démarche écologique quotidienne.<br/><br/>À travers une approche ludique, nous offrons à nos utilisateurs une série d'actions écologiques simples et récurrentes.<br/><br/>Notre objectif est de leur montrer qu'ils font partie d'une communauté engagée tout en leur permettant d'évaluer les impacts de leurs actions.<br/><br/></p>
                </div>
            </div>
            <div className="actions">
                <Caroussel slides={actions}></Caroussel>
            </div>
            <div className="infos">
                <div className="section">
                    <img src="./Gang d'oiseau.svg" alt="gang"></img>
                    <div className="content">
                        <h2>Plateforme communautaire</h2>
                        <p>Incarnez l’oiseau que vous voulez et rejoignez une communauté motivée.</p>
                    </div>
                </div>
                <div className="section">
                    <div className="content">
                        <h2 style={{ color: '#45978d' }}>Une motivation au top</h2>
                        <p>On t'aide à prendre l'habitude de faire tes actions quotidiennes grâce à nos fonctionnalités ludiques et les rappels de Tupi.</p>
                    </div>
                    <img src="./Fresque.svg" alt="fresque"></img>
                </div>
            </div>
        </div>
    )
}

export default Project;
import React from 'react';
import logo from './logo.svg';
import './App.css';
import Router from './utils/Router'
import TopBar from './components/topbar/TopBar';

function App() {
  return (
    <div className="App">
      <TopBar></TopBar>
      <Router></Router>
    </div>
  );
}

export default App;

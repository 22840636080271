import React from "react";
import "./LandingScreen.scss"

const LandingScreen: React.FC = () => {
    return (
        <div id="waccueil" className="landing-screen">
            <div className="title-container">
                <img src="https://ugram-s3.s3.us-east-2.amazonaws.com/coli4.png" alt="Tupi"></img>
                <h1>Tupi</h1>
                <h2>L'innovation sociale et écologique</h2>
            </div>
            <video muted autoPlay loop>
                <source src="https://ugram-s3.s3.us-east-2.amazonaws.com/landingScreenTupi.mp4" type="video/mp4"/>
            </video>
            <div className="video-copyright">all the copyrights of this video id owned by "BBC Studios"</div>
        </div>
    )
}

export default LandingScreen;
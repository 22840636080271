import React from "react";
import "./Roadmap.scss"
import Timeline from "../../components/timeline/Timeline";

const Roadmap: React.FC = () => {
    const roadmap_steps = {
        "story": [
            {
                "title": "Naissance de l'idée de Tupi",
                "date": "septembre 2022",
                "text": "C'est au cours de la piscine \"Moonshot\", une période de créativité intense où les étudiants d'Epitech développent des projets novateurs, que l'idée de Tupi s'est formée. À l'origine, orientée vers un réseau social, cette idée s'est affinée vers une approche plus ludique en faveur de l'environnement et de récompenser les gestes écologiques."
            },
            {
                "title": "Epitech Experience 3ème année",
                "date": "1er mars 2023",
                "text": "Le forum Epitech Experience est un espace dédié à la présentation des projets des étudiants. C'est lors de cette édition de 2023 pour les projets de 3ème année, que nous avons eu l'occasion de présenter Tupi aux visiteurs. La participation de Tupi à l'Epitech Experience a été une opportunité de présenter notre projet à un public varié, mais également d'obtenir une validation externe de son potentiel et de son intérêt."
            },
            {
                "title": "Beta de Tupi",
                "date": "juin 2024",
                "text": "Après plusieurs mois de travail, Tupi sortira officiellement sa version bêta en juin 2024. Cette étape marquera une étape importante dans le parcours du projet, permettant de récolter des retours précieux de nos bêtatesteurs, et ainsi d'améliorer notre application mobile afin d'offrir une expérience la plus agréable à nos utilisateurs.<br/><br/>La version bêta de Tupi visera un public varié, de préférence les 13-28 ans étant les plus sensibles à l'écologie. Cette étape nous permettra de confirmer l'intérêt de Tupi en tant qu'outil pour l'écologie."
            },
            {
                "title": "Partenariat avec des associations",
                "date": "à venir",
                "text": "Une fois que l'application sera lancée et aura un nombre conséquent d'utilisateurs réguliers, nous envisageons de nous engager auprès d'associations. Ce partenariat aura pour but de proposer leurs activités sur notre plateforme, et faciliter leurs recherches de volontaires."
            }
        ]
    }

    return (
        <div className="roadmap">
            <div className="decorative"></div>
            <div className="content">
                <h1>Evolution du projet</h1>
                <Timeline stories={roadmap_steps.story}></Timeline>
            </div>
            {/* <img src="./tupi.png" alt="big logo"></img> */}
        </div>
    )
}

export default Roadmap;
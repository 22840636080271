import React, { FunctionComponent } from 'react';
import  * as DOMPurifyI from 'dompurify';

interface SafeHTMLProps {
  htmlContent: string;
}

const SafeHTML: FunctionComponent<SafeHTMLProps> = ({ htmlContent }) => {
  const sanitizedHTML = DOMPurifyI.sanitize(htmlContent);
  return <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />;
};

export default SafeHTML;

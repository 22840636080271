import React, { useState } from "react";
import SafeHTML from "../../utils/safeHtml";
import "./Timeline.scss"

interface TimelineData {
    stories: {
        date: string,
        title: string,
        text: string
    }[],
}

const Timeline: React.FC<TimelineData> = ({ stories }) => {
    const [expandedIndex, setExpandedIndex] = useState(-1);

    const handleToggleDescription = (index: number) => {
        if (index === expandedIndex) {
            setExpandedIndex(-1);
        } else {
            setExpandedIndex(index);
        }
    };

    return (
        <div className="timeline">
            <div id="wroadmap" className="whitespace"></div>
            {stories.map((s, index) => {
                const isExpanded = index === expandedIndex;
                return (
                    <div key={index} className="events">
                        <div className="date">{s.date}</div>
                        <div className="event">
                            <span onClick={() => handleToggleDescription(index)} className={`${isExpanded ? "expanded" : "not-expanded"}`} />
                            <div className={`event-description ${isExpanded ? "expanded" : ""}`}>
                                <h4 onClick={() => handleToggleDescription(index)}>{s.title}</h4>
                                {isExpanded && (
                                    <div>
                                        <SafeHTML htmlContent={s.text}></SafeHTML>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    );
}

export default Timeline
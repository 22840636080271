import React, { ReactNode } from 'react';
import { Link, LinkProps } from "react-router-dom";

interface NavigateToProps extends LinkProps {
  to: string;
  children: ReactNode;
}

const NavigateTo: React.FC<NavigateToProps> = ({ to, children, ...props }) => {
    return (
        <Link to={to} {...props}>
            {children}
        </Link>
    );
};

export default NavigateTo;
import React, { useState } from "react";
import "./Team.scss"

const Team: React.FC = () => {
    const members = [
        {
            "name": "Elliot Giron-Michel",
            "role": "Chef d'équipe du Back",
            "img": "./elliot.svg"
        },
        {
            "name": "Florian Labarre",
            "role": "Développeur Back",
            "img": "./florian.svg"
        },
        {
            "name": "Guillaume Gauvain",
            "role": "Développeur Back",
            "img": "./guillaume.svg"
        },
        {
            "name": "Yannis Defontaine",
            "role": "Développeur Back",
            "img": "./yannis.svg"
        },
        {
            "name": "Mathis Laurent",
            "role": "Chef d'équipe du Front",
            "img": "./mathis.svg"
        },
        {
            "name": "Aurélien Le Roux",
            "role": "Développeur Front",
            "img": "./aurelien.svg"
        },
        {
            "name": "Loïc Ye",
            "role": "Développeur Front",
            "img": "./loic.svg"
        },
        {
            "name": "Noah Vassards",
            "role": "Développeur Front",
            "img": "./noah.svg"
        },
        {
            "name": "Thomas Laboucheix",
            "role": "Développeur Front",
            "img": "./toma.svg"
        }
    ];

    const [idx, setIdx] = useState(-1)

    return (
        <div className="team">
            <div id="wwhoarewe"></div>
            <h2>Qui sommes nous</h2>
            <div className="team-container">
                {members.map((member, index) => {
                    if (idx === index) {
                        return (
                            <div className="team-item">
                                <img src={member.img} loading="lazy" alt={member.name} onMouseEnter={() => { setIdx(index) }} onMouseLeave={() => { setIdx(-1) }}></img>
                                <div className="member-description">
                                    <h3>{member.name}</h3>
                                    <p>{member.role}</p>
                                </div>
                            </div>)
                    } else {
                        return (
                            <div className="team-item">
                                <img src={member.img} loading="lazy" alt={member.name} onMouseEnter={() => { setIdx(index) }} onMouseLeave={() => { setIdx(-1) }}></img>
                            </div>
                        )
                    }
                })}
            </div>
        </div>
    )
}

export default Team;
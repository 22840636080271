import { Route, Routes } from "react-router-dom"

// pages imports
import Home from "../pages/Home"

const Router = () => {
    return (
        <div>
            <Routes>
                <Route path="/" element={<Home />} />
            </Routes>
        </div>
    )
}

export default Router
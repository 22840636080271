import React, {useEffect, useRef } from "react";
import NavigateTo from "../../utils/NavigateTo";
import "./TopBar.scss"

interface tabData {
        name: string,
        to: string,
        id: string
}

const TopBar: React.FC = () => {
    const tabs: tabData[] =
        [
            {
                "name": "Acceuil",
                "to": "/",
                "id": "accueil"
            },
            {
                "name": "Le projet",
                "to": "/",
                "id": "projet"
            },
            {
                "name": "Roadmap",
                "to": "/",
                "id": "roadmap"
            },
            {
                "name": "Qui sommes nous",
                "to": "/",
                "id": "whoarewe"
            }
        ]
    ;

  const handleScroll = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

return (
        <div className="topbar">
            <div className="title">
                <img src="./light-tupi.png" alt="tupi-logo"></img>
                <h1>Tupi</h1>
            </div>
            <div className="tabs">
                {tabs.map((s) => {
                    return (
                        <NavigateTo to={s.to}>
                            <button onClick={event => handleScroll('w'+s.id)} id={s.id}>{s.name}</button>
                        </NavigateTo>
                    )
                })}
            </div>
        </div>
    )
}

export default TopBar;